import IconsFacebook from '~icons/icons/Facebook.svg'
import IconsInstagram from '~icons/icons/Instagram.svg'
import IconsX from '~icons/icons/XIcon.svg'
import IconsYoutube from '~icons/icons/Youtube.svg'
import IconsTikTok from '~icons/icons/TikTok.svg'
import IconsLinkedin from '~icons/icons/linkedin.svg'
import IconsLine from '~icons/icons/Line.svg'

export type IconType = keyof typeof iconsMap

export const iconsMap = {
  facebook: IconsFacebook,
  instagram: IconsInstagram,
  x: IconsX,
  youtube: IconsYoutube,
  tiktok: IconsTikTok,
  linkedin: IconsLinkedin,
  line: IconsLine,
}
